@charset "utf-8";
// 色を変数化して保管


$color_gray01:#ddd;
$color_gray02:#D5D5D5;
$color_gray03:#eee;
$color_red01:#b32121;



.c-fc__red{
    color: $color_red01;
}