@charset "utf-8";
@use '../../foundation/mixin' as m;
@use '../../foundation/color' as c;







////////////////////////////////////////////

#top.l-main{
  margin-top: var(--headerHeight,80px) !important;
  padding: 0;
  section[class*='p-top']{
      margin-bottom: 200px;
      @include m.mq(sm){
          margin-bottom: 100px;
      }
      &:last-child{
          margin-bottom: 0;
      }
  }
}

// :is(.p-topQuicknav,.p-topPick,.l-campaign){
//   .swiper-button-next,
//   .swiper-button-prev{
//       background: none;
//       // width: clamp(12px,2em,30px);
//       // height: clamp(12px,2em,30px);
//       width: min(1.5em,4vw);
//       height: min(1.5em,4vw);
//       margin: 0;
//       font-size: inherit;
//   }
//   .swiper-button-next{
//       border: {
//           right: 3px solid #333;
//           bottom: 3px solid #333;
//       };
//       transform: rotate(-45deg) translateY(-50%);
//       // right: -2em;
//       right: 0.5em;
//   }
//   .swiper-button-prev{
//       border: {
//           left: 3px solid #333;
//           bottom: 3px solid #333;
//       };
//       transform: rotate(45deg) translateY(-50%);
//       // left: -2em;
//       left: 0.5em;
//   }


// } 


.p-topQuicknav{
  background-color: c.$color_gray03;
  padding:30px 0 ;
  @include m.mq(sm){
    padding:10px 0 ;
  }
  &__listBox{
    position: relative;
  }
  &__listWrapper{
    overflow: hidden;
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    @include m.mq(tab){
      flex-wrap: nowrap;
    }
    >li{
      width: 20%;
      padding: 5px;
      img{
        border-radius: 5px;
      }
      @include m.mq(sm){
        width: 20%;
        //margin: 0 2.5%;
      }
    }
    &__nav{
        display: none;
        @include m.mq(tab){
          display: block;
        }
    }
  }
}



.p-topPick{
  @include m.mq(tab){
    .c-inner__1200{
      width: 100%;
      padding: 0;
    }
  }
  &__list{
    margin-top: 100px;
    >li{
      display: flex;
      &:nth-child(even){
        flex-direction: row-reverse;
      }
      @include m.mq(tab){
        flex-direction: column !important;
      }
    }
    &__visual{
      width: 50%;
      overflow: hidden;
      text-align: center;
      @include m.mq(tab){
        width: 100%;
      }
    }
    &__block{
      width: 50%;
      overflow: hidden;
      position: relative;
      @include m.mq(tab){
        width: 100%;
      }
    }
    &__slider{
      // display: flex;
      // align-items: center;
      // justify-content: center;
      >li{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px;
        @include m.mq(tab){
          padding: 15% 10px;
        }
      }
      &__visual{
        max-width: 400px;
        width: 80%;
        margin: 0 auto;
      }
      &__block{
        margin-top: 1%;
        text-align: center;
      }
      &__name{
        font-size: min(1.4rem,1.8vw);
        line-height: 1;
        @include m.mq(sm){
          font-size: min(1.2rem,3.0vw)
        }
      }
      &__brand{
        font-size: 0.7em;
        display: block;
        line-height: inherit;
        margin-bottom: 5px;
      }
      &__item{
        font-size :1em;
        display: block;
        line-height: inherit;
      }
      &__price{
        margin-top: 1em;
      }
    }
    &__nav{
      position: absolute;
      bottom: 3%;
      width: 100%;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 0.7rem;
      :is(.swiper-button-prev,.swiper-button-next,.swiper-pagination){
        position: static !important;
        //transform: translateY(0);
        width: 5em !important;
        height: 1.2em !important;
        margin: 0 20px !important;
        border-width: 1px !important;
        
      }
      .swiper-button-prev{
        transform: skew(-30deg) !important;
      }
      .swiper-button-next{
        transform: skew(30deg) !important;
      }
      .swiper-pagination{
        font-size: 1.4em;
        color: c.$color-gray01;
      }
    }
  }
}


.p-sec01{
    margin-bottom: 100px;
    // &__listBox{
    //   position: relative;
    // }
    // &__listWrapper{
    //   overflow: hidden;
    // }
    &__list{
        //display: flex;
        //flex-wrap: wrap;
        //overflow-x: auto;


        display: flex;
        margin-top: 10px;
        @include m.mq(tab){
          flex-wrap: wrap;
          justify-content: space-between;
        }
        >li{
            //width: max(18%,200px);
            //min-width: 100px;
            margin-right: 2%;
            margin-bottom: 10px;
            text-align: center;
            // flex-basis: min(35%,200px);
            // flex-shrink: 0;

            width: calc((100% - (2% * 4)) / 5);
            @include m.mq(tab){
              width: 48%;
              margin: 0 0 4%;
              &:first-child{
                width: 100%;
                .p-sec01__list__visual{
                  max-width: 60%;
                  margin:0 auto;
                }
              }
            }
            &:nth-child(5) ~ li{
              display: none;
            }
        }
        &__visual{

        }
        &__block{
            font-size: min(3vw,1.4rem);
            line-height: 1.1;
        }
        &__name{
            line-height: inherit;
            word-break: break-all;
            font-size: inherit;
        }
        &__price,
        &__soldout{
            margin-top: 1em;
            line-height: inherit;
            span{
              display: block;
              line-height: inherit;
              margin-top: 0.5em;
            }
        }
        &__btn{
          font-size: 1.6rem;
        }
    }
    
}

.p-topNewarrival{

}

.p-topRanking{
  .p-sec01{
    &__list{
      counter-reset: rank 0;
      >li{
        counter-increment: rank;
        &:nth-child(1) .p-sec01__list__visual::after{background: #ddc980;}
        &:nth-child(2) .p-sec01__list__visual::after{background: #b6b6b6;}
        &:nth-child(3) .p-sec01__list__visual::after{background: #eda459;}
      }
      &__visual{
        position: relative;
        &::after{
          content: counter(rank);
          background: #dedede;
          border-radius: 50%;
          position: absolute;
          top: 0;
          left: 0;
          width: 30px;
          height: 30px;
          line-height: 1;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
  
}


.p-topSnap{
  background-color: #333;
  padding: 50px 0;
  .c-ttl03{
      &::after{
          background-color: #fff;
      }
      &__inner{
          background-color:#333;
          span{
              &.ja{
                  color: #fff;
              }
          }
      }
  }
  &__list{
      display: flex;
      flex-wrap: wrap;
      margin-top: 50px;
      >li{
          width: 25%;
          position: relative;
          @include m.mq(tab){
            width: 50%;
          }
          &::before {
              content: "";
              display: block;
              width: 100%;
              height: 20%;
              background: linear-gradient(rgba(0, 0, 0, 0) 0%, #000 100%);
              position: absolute;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;
          }
          
          
      }
      &__visual{
          a{
              position: relative;
              display: block;
              &::after{
                  content: "read more";
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 100%;
                  height: 100%;
                  position: absolute;
                  top: 0;
                  left: 0;
                  background-color: rgba(0,0,0,0.7);
                  color: #fff;
                  font-weight: bold;
                  font-size: #{'min(2.0rem,4vw)'};
                  letter-spacing: 0.1em;
                  transition: 0.3s;
                  opacity: 0;
                  visibility: hidden;
                  z-index: 3;
              }
              &:hover{
                  opacity: 1 !important;
                  &::after{
                      opacity: 1;
                      visibility: visible;
                  }
              }
          }
      }
      &__bio{
          position: absolute;
          left: 0;
          bottom: 0;
          right:0;
          padding: 3%;
          z-index: 2;
          display: flex;
          align-items: center;
          &__avatar{
              width: #{'max(24%,44px)'};
              border: 1px solid #EEEEEE;
              background-color: #fff;
              border-radius: 100px;
              overflow: hidden;
              aspect-ratio: 1/1;
          }
          &__block{
              margin-left: 1em;
              color: #fff;
              &__shop{
                  line-height: 1;
              }
              &__name{
                  line-height: 1;
              }

          }
      }
      

  }
}


// .p-topSearch{
//     background-color: c.$color_gray03;
//     padding: 80px 0;
//     .c-ttl03{
//         &__inner{
//             background-color: c.$color_gray03;
//         }
//         span.en{  
//             color: #bdbdbd;
//         }
//     }
//     &__list{
//         display: flex;
//         flex-wrap: wrap;
//         margin-top: 50px;
//         @include m.mq(tab){
//             justify-content: space-between;
//         }
//         >li{
//             border: 1px solid #DDDDDD;
//             height: min(350px,30vw);
//             @include m.flexList(30px,3);
//             @include m.mq(tab){
//                 height: auto;
//                 @include m.flexList(2%,2);
//             }
//             > a{
//                 background-color: #fff;
//                 display: flex;
//                 flex-direction: column;
//                 align-items: center;
//                 width: 100%;
//                 height: 100%;
//                 padding: 5%;
//             }
//         }
//         &__visual{
//             margin: auto;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             @include m.mq(sm){
//                 margin-bottom: 10%;
//             }
//             img{
//                 height: 10vw;
//                 max-height: 120px;
//                 width: auto;
//                 @include m.mq(sm){
//                     height: 15vw;
//                 }
//             }
//         }
//         &__block{
//             margin: 0;
//             text-align: center;

//         }
//         &__ttl{
//             font-weight: bold;
//             font-size:min(1.8vw,2rem);
//             @include m.mq(sm){
//                 font-size:min(2.3vw,1.3rem);
//             }
//         }
//         &__link{
//             font-size: min(1.3vw,1.2rem);
//             letter-spacing: 0.1em;
//             line-height: 1;
//             margin-top: 1.5em;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             @include m.mq(sm){
//                 font-size:min(1.8vw,1.0rem);
//             }
//             &::after{
//                 @include m.nextArrow();
//             }
//         }
//     }
// }




.p-topCSR{
    &__list{
        margin-top: 50px;
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        >li{
        //    width: ;
            margin: {
                bottom: 20px;
                // right:3%;
                // left: 3%;
            }
            text-align: center;
        }
    }
}


.p-topSNS{
    @-moz-document url-prefix(){
      display:none;
      opacity: 0;
      visibility:hidden;
    }
    &__list{
        margin-top: 50px;
        display: flex;
        flex-wrap: wrap;
        >li{
            position: relative;
            overflow: hidden;
            @include m.flexList(10px,5);
            @include m.mq(tab){
                &:nth-child(5n){
                    margin-right: auto;
                }
                &:nth-child(9) ~ li{
                    display: none;
                }
                @include m.flexList(10px,3);
            }
            &::before{
                content: "";
                display: block;
                padding-bottom: 100%;
            }
        }
        &__visual{
            position: absolute;
            display: block;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            img{
              object-fit: cover;
              height: 100%;
              width: 100%;
            }
        }
    }
}





@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}





.p-topic{
  &__slider{
    position: relative;
    &__vertical{
      width: 100%;
      height: 100%;
      height: calc((var(--vh, 1vh) * 100 - var(--headerHeight,80px)));
      //margin-top: var(--headerHeight,80px);
      overflow: hidden;
      position: relative;
      &__inner{
        flex-direction: column;
        &.scrolloff::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 12;
        }
      }
      &__slide{
        margin: 0 !important;
      }
      &__nav{
        position: absolute;
        z-index: 10;
        right: 2% !important;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        max-height: 50vh;
        .swiper-pagination-bullet{
          margin: 10px !important;
          background: transparent;
          border: 1px solid #fff;
          opacity: 0.8;
          &.swiper-pagination-bullet-active{
            opacity: 1 !important;
            background: #fff !important;
            //background-color: #056d29;
          }
        }
      }//nav
    }//vertical
    &__horizon{
      width: 100%;
      height: 100%;
      &__slide{
        overflow: hidden;
        width: 100%;
        height: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        &::before{
          content: "";
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: rgba(50, 50, 50, 0.15);
          z-index: 1;
        }
        &.swiper-slide-active{
          .p-topic{
            &__slider{
              &__cont{
                -webkit-animation: fadeIn .3s ease-in-out .4s 1 normal both;
                animation: fadeIn .3s ease-in-out .4s 1 normal both;
              }
              
            }

          }
        }
      }
      &__nav{
        bottom: calc(5% + var(--fixFooterBottomHeight,0px));
        font-size: 2.0rem;
        color: #fff;
        letter-spacing: 0.1em;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        max-width: 1000px;
        width: 75vw;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        @include m.mq(sm){
          bottom: 10%;
          font-size: 1.5rem
        }
        > * {
          position: static !important;
          /* background: #fff !important; */
          color: #fff !important;
          margin: 0;
          background-image: none;
        }
        [class^=swiper-button] {
          width: auto;
          min-width: 64px;
          height: auto;
          font-size: 1.4rem;
          display: flex;
          align-items: center;
          @include m.mq(sm){
            
            min-width: 64px;
            font-size: 1.4rem;
            .swiper-button-prev::before,
            .swiper-button-next::after{
              width: 1.2em;
              height: 1.2em;
              
            };
          }
        }
        .swiper-button-prev{
          &::before {
            content: "";
            display: block;
            width: 1.2em;
            height: 1.2em;
            border-bottom: 2px solid #fff;
            border-left: 2px solid #fff;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
        }
        .swiper-button-next{
          &::after {
            content: "";
            display: block;
            width: 1.2em;
            height: 1.2em;
            border-bottom: 2px solid #fff;
            border-right: 2px solid #fff;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
        }
        @include m.mq(sm){
          .swiper-button-prev::before,
          .swiper-button-next::after{
            width: 1.2em;
            height: 1.2em;
          }
        }
         > * {
          position: static !important;
          /* background: #fff !important; */
          color: #fff !important;
          margin: 0;
          background-image: none;
        }

  
      }//nav
    }
    &__cont{
      position: relative;
      z-index: 2;
      font-size: 1.4rem;
      color: #fff;
      width: 100%;
      opacity: 0;
      @include m.mq(sm){
        font-size: 1.2rem;
      }
    }
    &__ttl{
      font-size: 3em;
      letter-spacing: 0.05em;
      @include m.mq(sm){
        font-size: 2.6em;
      }
    }
    &__txt{
      font-size: 1.0em;
      letter-spacing: 0.05em;
      line-height: 1.6;
      margin-top: 3em;
      max-width: 75%;
      @include m.mq(sm){
        margin-top: 3em;
      }
    }
    &__btn{
      margin-top: 3em;
      a{
        display: inline-block;
        background-color: #fff;
        color: #333;
        font-size: 1.2em;
        letter-spacing: 0.1em;
        padding: 10px 30px;
        @include m.mq(sm){
          font-size: 1.2em;
          padding: 10px 30px;
        }
      }
    }
    &__visual{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      z-index: 0;

      img{
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
    
  }
  &__inner{
    position: relative;
  }
  &__scroll{

  }
  &__anchor{
    position: absolute;
    bottom: 5%;
    right: 2%;
    display: inline-block;
    position: absolute;
    z-index: 2;
    padding: 10px 10px 110px;
    overflow: hidden;
    @include m.mq(sm){
      bottom: 10%;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 50%;
      display: block;
      width: 1px;
      height: 100px;
      background: #fff;
      -webkit-animation: sdl 2s cubic-bezier(1, 0, 0, 1) infinite;
              animation: sdl 2s cubic-bezier(1, 0, 0, 1) infinite;
    }
  }
}

@-webkit-keyframes sdl {
    0% {
      -webkit-transform: scale(1, 0);
              transform: scale(1, 0);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
    }
    30% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
    }
    70% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      -webkit-transform-origin: 0 100%;
              transform-origin: 0 100%;
    }
    100% {
      -webkit-transform: scale(1, 0);
              transform: scale(1, 0);
      -webkit-transform-origin: 0 100%;
              transform-origin: 0 100%;
    }
}

@keyframes sdl {
    0% {
      -webkit-transform: scale(1, 0);
              transform: scale(1, 0);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
    }
    30% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      -webkit-transform-origin: 0 0;
              transform-origin: 0 0;
    }
    70% {
      -webkit-transform: scale(1, 1);
              transform: scale(1, 1);
      -webkit-transform-origin: 0 100%;
              transform-origin: 0 100%;
    }
    100% {
      -webkit-transform: scale(1, 0);
              transform: scale(1, 0);
      -webkit-transform-origin: 0 100%;
              transform-origin: 0 100%;
    }
}


  /*# sourceMappingURL=style.css.map */