@charset "utf-8";
@use "sass:map";

// ブレイクポイント
// -----------------------------------------
$breakpoints: (
  "sm-se": "screen and (max-width: 350px)",
  "sm-s": "screen and (max-width: 370px)",
  "xr": "screen and (max-width: 480px) and (min-height: 700px)",
  "sm": "screen and (max-width: 500px)",
  "tab": "screen and (max-width: 768px)",
  "pc-s": "screen and (max-width: 980px)",
  "tab-l": "screen and (max-width: 1024px)",
  "hover": "screen and (min-width: 1025px)",
  "note": "screen and (max-width: 1200px)",
  "lets": "screen and (max-height: 760px) and (min-width: 1025px)",
  "pc13": "screen and (max-height: 660px) and (min-width: 1025px)",
  "pc12": "screen and (max-height: 600px) and (min-width: 1025px)",
);

@mixin mq($breakpoint: tab-l) {
  @media #{map.get($breakpoints, $breakpoint)} {
    @content;
  }
}

// object-fit:cover
// -----------------------------------------
@mixin image100 {
  picture,
  source,
  img {
    @include object-fit(cover);
    height: 100%;
    width: 100%;
  }
}

// css properties
// -----------------------------------------
@mixin centerX {
  left: 50%;
  transform: translateX(-50%);
}

@mixin centerY {
  top: 50%;
  transform: translateY(-50%);
}

@mixin center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin filter {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin bgCover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
@mixin bgContain {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@mixin descWhite {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
}


@mixin flexList($margin,$col){
  width:calc((100% - #{$margin * ($col - 1) }) /$col);
  margin: 0;
  @if ($col != 2){
    margin-right:#{$margin};
    &:nth-child(#{$col}n){
        margin-right: 0;
    }
  }
  &:nth-child(#{$col}) ~ li{
      margin-top: #{$margin};
  }
  
  
}


@mixin nextArrow($size:0.75em,$color:#333){
  content:"";
  display: block;
  width: $size;
  height: $size;
  border:{
    right:1px solid $color;
    bottom:1px solid $color;
  }
  margin-left: 1em;
  transform: rotate(-45deg);
}


@mixin DIN($weight:normal){
  font-family: din-2014,"Noto Sans Japanese","Teko","Noto Sans JP","游ゴシック体",YuGothic,"游ゴシック","Yu Gothic","ヒラギノ角ゴ Pro","Hiragino Kaku Gothic ProN","メイリオ",sans-serif;
  font-weight: $weight;
}